<template>
    <v-btn fab  elevation min-height="50" class="mx-1">
        <v-img :src="item.image"></v-img>
    </v-btn>
</template>
<script>
export default {
    name:'TheButton',
    props:{
        item:{
            required: true
        }
    }
}
</script>