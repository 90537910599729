<template>
  <v-row justify="center">
    <v-dialog
      value="1"
      fullscreen
      eager
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card class="customize bg-grey">
          <ItemDetails v-model="quantity" :item="item" @on-reset="isCartReset = true" />
          <BaseMessage v-if="isCartReset" v-model="isCartReset" @confirm="onReset({router: $router, clear: clear})" :title="translate('restart_order','Restart Order')">
            {{translate('confirm_reset_text','Are you sure you want to reset? Your orders will be removed and you will be taken to the homepage')}}
          </BaseMessage>

          <section class="d-flex justify-center">
          <v-container class="px-1 mx-1">
            <section class="content">
              <CategoryTab v-model="selectedCategory" v-if="false" :items="categories" class="mt-16" />
              <ModifierList v-for="(modifiergroup, i) in modifiergroups"
                ref="modifier"
                :parent-bus="bus"
                :modifiergroup="modifiergroup"                       
                :items="modifiergroup.modifiers"
                :title="modifiergroup.name | name "
                @active-item="addModifierItem"
                @select-size="setModifierBySize"
                :key="'modifier'+i"  />
              
              <ModifierList v-for="(modifiergroup, i) in comboModifierGroups"
                ref="component" 
                :parent-bus="bus"
                :is-combo="true"
                :modifiergroup="modifiergroup"                                 
                :items="modifiergroup.items"
                :title="modifiergroup.name | name"
                @active-item="addComboItem"
                @select-size="setModifierBySize"
                :key="'component-'+i"  />
            </section>
          </v-container>
          <v-footer color="primary" fixed app class="footer px-10 d-flex justify-space-between">
            <solo-button @click="$router.push({name: 'menu.index'})" inverse-outlined width="307">{{translate('cancel', 'cancel')}}</solo-button>
            <solo-button inverse width="644" @click="addItem">
              <v-icon class="mx-3" size="50">mdi-cart</v-icon>
                {{translate('add_to_cart','ADD TO CART')}}
            </solo-button>
          </v-footer>
          </section>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from 'vue';
import global from '@/mixins/global'
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import { mapGetters, mapMutations } from 'vuex';
import ModifierList from "@/components/modifier/ModifierList.vue";
import ItemDetails from '@/components/modifier/ItemDetails.vue';
// import CartReset from '@/components/cart/CartReset.vue';
import SoloButton from '@/components/base/SoloButton.vue';
import BaseMessage from '@/components/base/BaseMessage';
import CategoryTab from '@/components/category/CategoryTab.vue';
export default {
  name: "CustomizedItem",
  components: {
    ItemDetails,
    ModifierList,
    SoloButton,
    BaseMessage,
    CategoryTab
  },
  mixins: [itemMixin, translationMixin, global],
  data: () => ({
    model: true,
    quantity:1,
    isCartReset: false,
    selectedCategory: 0,
    modifiergroups: [],
    comboModifierGroups: [],
    comboItems: [],
    modifiers: [],
    item: {},
    bus: new Vue()
  }),
  computed: {
    ...mapGetters({
      menus: 'cart/getMenu'
    }),
    categories(){
      return[
        {name: 'Meal Deal'},
        {name: 'Sandwich only'},
      ]
    }    
  },
  mounted() {
    this.findItem()
  },
  methods:{
    ...mapMutations({
      add: 'cart/add',
      clear: 'cart/clear'
    }),
    onReset(){
      
    },
    addComboItem(item) {
      this.comboItems.push({
        id: item.id,
        ingredients: [],
        modifiers: [],
        name: item.name,
        price: item.price,
        notes: '',
        quantity: item.quantity,
        imgUrl: item['image-uri']
      })
    },
    addModifierItem(item) {          
      console.log(item)
      this.modifiers.push({
        id: item.id,
        name: item.attributes.name,
        price: item.attributes.price,
        notes: '',
        quantity: item.quantity,
        imgUrl: item.attributes['image-uri'],
        isSize: item.isSize
      })
    },
    addItem() {
      this.comboItems = []
      this.modifiers = []
      this.bus.$emit('emit-active-items')
      this.$nextTick(() => {
        var item = {
          id: this.item.id,
          price: this.item.price,
          description: this.item.description,
          quantity: this.quantity,
          notes: "notes",
          name: this.item.name,
          imgUrl: this.item['image-uri'],
          discount: 0,
          'combo-items': this.comboItems,
          modifiers: this.modifiers,
          ingredients: [],
          total: this.getTotalPrice(),
        }
        console.log(item)                 
        this.add(item)
        this.$router.push({name:'menu.index', query: { item: this.name(this.item.name) }})
      })
    },
    getTotalPrice() {      
      let hasSize = this.modifiers.filter(modifier => { return modifier.isSize })
      if(this.item['is-combo'] || (!this.item['is-combo'] && hasSize.length)) {
        return this.modifierPrice(this.comboItems) + this.modifierPrice(this.modifiers)
      } else if(!hasSize.length) {
        return this.item.price + this.modifierPrice(this.comboItems) + this.modifierPrice(this.modifiers)
      }
    },    
    modifierPrice(items = []) {
      return items.map(modifier=>{ 
        if(modifier.price) {
          return modifier.price * modifier.quantity 
        }        
        return 0
      }).reduce((a, b) => a + b, 0)
    },
    setModifierBySize(size) {      
      if(this.item['is-combo']) {
        this.getComboModifierGroups(size)
      }      
    },
    getComboModifierGroups(size) {

      let comboSizeItem = this.item.combo.filter(item => {
        return item.id == size.id
      })
      
      this.comboModifierGroups = []
      
      this.$nextTick(() => {
        this.comboModifierGroups = comboSizeItem[0].components        
      })

    },
    findItem() {
      this.menus.data.map(menu => {
        if(menu.id == this.$route.params.catId) {
          menu.attributes.items.map(item => {
            if(item.id == this.filterId(this.$route.params.itemId)) {              
              this.item = item                
              this.modifiergroups = this.getModifierGroups().map(modifiergroup => {                
                return {...modifiergroup, modifiers: this.filterModifiers(modifiergroup.modifiers)}
              })              
            }            
          })
        }
      })
    },
    getModifierGroups() {
      let modgroup_ids = this.item['modifier-groups'].map((modgroup) => {
          return modgroup.id
      })
      return this.filterModifierGroups(modgroup_ids)
    },
    filterModifierGroups(modgroup_ids) {
      return this.menus.included.modifierGroups.filter(modgroup => {          
        return modgroup_ids.includes(modgroup.id)
      }).map(modgroup => {            
        return {id: modgroup.id, ...modgroup.attributes}
      })
    },
    filterModifiers(modifier_ids) {
      return this.menus.included.modifiers.filter(modifier => {
        return modifier_ids.includes(modifier.id)
      })
    },
  }
};
</script>
<style scoped lang="scss">

.container{
  max-width: 1000px !important;
}
.footer{
  height: 214px;
  box-shadow: 0px -23px 46px rgba(0, 0, 0, 0.03) !important;
}
.content{
  margin-top: 600px;
  margin-bottom: 230px;
}
.category-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
}
</style>