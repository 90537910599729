<template>
    <v-row justify="center">
        <v-dialog v-model="model" eager persistent width="822"> 
            <v-card class="page">
                <v-container class="px-6">
                    <h1 class="text-center page-title my-5 ">{{title}}</h1>
                    <p class="text-center page-description mb-8">
                        <slot></slot>
                    </p>
                    <section class="d-flex justify-center">
                        <solo-button class="ma-5" @click="$emit('confirm')" width="300">{{confirmLabel}}</solo-button>
                        <solo-button class="ma-5" @click="model = false" width="300" outlined>Cancel</solo-button>
                    </section>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import SoloButton from '@/components/base/SoloButton.vue';
export default {
    name:'BaseMessage',
    components:{
        SoloButton
    },
    props:{
        confirmLabel:{
            type: String,
            default: 'Restart'
        },
        title:{
            type: String,
            default: null
        },
        value:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        model:{
            get(){
                return this.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }     
}
</script>
<style lang="scss" scoped>
    .page{
        color: #1E1E1E !important;
        &-title{
            color: var(--v-primary-base);
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 48px !important;
            line-height: 96px !important;
        }
        &-description{
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }
        .btn{
            border-radius: 20px !important;
            width: 300px !important;
            height: 123px !important;
            font-style: normal !important;
            font-weight: bold;
            font-size: 36px;
            text-align: center !important;
            &-restart{
                text-transform: capitalize !important;
                background: var(--v-primary-base) !important;
                color: #FFFFFF;
            }
            &-cancel{
                text-transform: capitalize !important;
                border: 6px solid !important;
            }
        }
    }
</style>