<template>
  <v-item-group v-model="model">
    <section class="category-group d-flex align-center justify-space-between">
      <v-item v-for="(item, index) in items" :key="index" v-slot="{ toggle }">
        <section :class="[model == index ? 'active' : null]" @click="toggle">
          <div class="d-flex justify-center align-center item">
            {{ item.name }}
          </div>
        </section>
      </v-item>
    </section>
  </v-item-group>
</template>
<script>
export default {
  name: "CategoryGroup",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.active {
  background: var(--v-primary-base);
  border-radius: 67px;
  color: #fff;
}
.category-group {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 67px;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.54);
  .item {
    width: 460px;
    height: 86px;
    cursor: pointer;
  }
}
</style>