<template>
    <section>
        <TheButton v-for="(allergen, i) in allergens" :key="i" :item="allergen" />
    </section>
</template>
<script>
import TheButton from '@/components/base/TheButton.vue';
export default {
    name:'ButtonList',
    components:{
        TheButton
    },
    props: {
        allergens: []
    }
}
</script>